.modern-list {
    /* Unordered List Styles */
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .modern-list li {
    /* List Item Styles */
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  
  .modern-list li:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  .modern-list li span {
    /* List Item Text Styles */
    font-size: 16px;
    color: #333;
  }
/* App.css */

/* Styling for the form container */
form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
  border: 2px dashed black;
  padding: 15px;
  border-radius: 5px;
}

/* Styling for the textarea */
textarea {
  margin-bottom: 10px;
  padding: 12px 20px;
  resize: vertical;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  background-color: #f9f9f9;
  color: #333;
  transition: all 0.3s ease;
}

textarea:focus {
  outline: none;
  border-color: #4CAF50;
  background-color: #ffffff;
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
}

/* Styling for the button */
button {
  padding: 12px 20px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color:#b232129a;
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  transition: all 0.3s ease;
}

button:hover {
  background-color: #B23212;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

button:active {
  background-color: #B23212;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Toggle switch styling */
.toggle-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.toggle-label {
  margin-right: 10px;
  font-size: 16px;
  color: #333;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 50px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  border-radius: 50%;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .toggle-slider {
  background-color: #B23212;
}

input:checked + .toggle-slider:before {
  transform: translateX(26px);
}

 
  