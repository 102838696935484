
.services-container {
  max-width: 1000px;
  margin-top: 40px; 
  background: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow-y:auto;
  box-sizing: border-box;
}


.category-cards, .service-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
}

.category-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 5px;
}

.category-button {
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px 10px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s ease, transform 0.2s ease;
  color: #343a40;
}

.category-button:hover {
  background: #e9ecef;
  transform: scale(1.05);
}

.category-button.active {
  background: #b23212;
  color: white;
  border: none;
}
.service-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
}

.service-card {
  width: 300px;
  height: 100px; /* Reduced from 200px */
  perspective: 1000px;
  cursor: pointer;
  margin: 10px;
  position: relative;
}

.service-card:hover {
  transform: scale(1.05);
}



/* Active state for flipped card */
.service-card.active {
  transform: rotateY(180deg);
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.service-card.flipped .card-inner {
  transform: rotateY(180deg);
}

.card-front, .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px; /* Reduced padding */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}
.card-front {
  background: #f8f9fa;
  color: #343a40;
}

.card-back {
  background: #b23212;
  color: white;
  transform: rotateY(180deg);
}

/* Media Queries for responsive layout */
