/* AboutPage.css */
.about-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Arial', sans-serif;
  color: #333;
  line-height: 1.3;
}

h1 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}


  .about-content {
  background-color: #f9f9f9;
  border-radius: 2px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  vertical-align: top;
}  



@media (max-width: 768px) {
  .about-page {
    padding: 1rem;
  }

  h1 {
    font-size: 2rem;
  }

  .about-content {
    padding: 1.5rem;
  }
}