

/* Banner Styling */
.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(#b23212, black);
  color: white;
  height: 110px;
  padding: 0px;
  text-align: left;
  position: fixed;
  overflow: hidden;
  width: 100%;
}


@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
}

.logo {
  height: 100px; /* Adjust as needed */
  width: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Prevent distortion */
  display: block;
}

/* Add animation class */
.logo.animate {
  animation: shake 0.5s ease-in-out; /* Apply the shake animation */
  animation-iteration-count: 10; /* Shake for 5 seconds (10 iterations of 0.5s each) */
}

.logo-flipped {
  height: 100px; /* Adjust as needed */
  width: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Prevent distortion */
  display: block;
  transform: scaleX(-1); /* Always flipped horizontally */
}

.logo-flipped.animate {
  animation: shake 0.5s ease-in-out; /* Apply the shake animation */
  animation-iteration-count: 10; /* Shake for 5 seconds (10 iterations of 0.5s each) */
}

/* Text Styling */
.banner-text {
  font-family: 'Arial', sans-serif;
  font-size: 2rem;
  font-weight: bold;
  margin-left: 20px; /* Space between logo and text */
  color: white;
}


.home_content img {
  position: relative;
  top: 110px; /* Increased top value to shift content down below banner */
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container */
}
