@import "../../css/_variables.css";

nav {
    background-color: var(--nav-background-color);
    padding: 0px;
    position: fixed;
    top: 0;
    width: 100%;
}
  
nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
 }
  
nav li {
    font-size: large;    
}

nav a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--nav-link-color-inactive);
    padding-top:1px;
    padding-left: 0px;
    padding-right: 10px;
    padding-bottom: 0px;

}

nav a.active {
    background-color: var(--nav-link-background-active);
    color: var(--nav-link-color-active);
    font-size: larger;
    height: var(--hc-nav-height);
    padding-left: 2px;
    }

.hc_logo_small{
        width: var(--hc-nav-height);
        height: var(--hc-nav-height);       
    }

.hc_logo_small a {
    background-color: #000000;
    color: var(--nav-link-color-active);
}
.hc_nav_icon {
    color: var(--nav-link-color-inactive);
    size: 100;
    padding-right: 5px;
    
}

.hc-nav-icon-spacing {
    padding-right: 4px;
}

.responsive-logo {
    max-height: 40px;
    width: auto;
    display: block;
  }
  
  /* Mobile specific styles */
  @media screen and (max-width: 768px) {
    .responsive-logo {
      max-height: 30px;
      width: auto;
    }
    
    .logo-link {
      display: flex;
      align-items: center;
    }
  
    nav ul {
      display: flex;
      align-items: center;
      font-size: x-small;
    }
  
    nav ul li {
      display: flex;
      align-items: center;
    }
  }
  
