:root {
    /* Navigation */
    --nav-background-color: #b23212;
    --nav-link-background-active: #ffffff;
    --nav-link-color-active: var(--nav-background-color);
    --nav-link-background-inactive: var(--nav-background-color);
    --nav-link-color-inactive: #efe9e9;
    --hc-nav-height: 60px;

    /* Footer */
    --footer-background-color: var(--nav-background-color);

    /* Body styles */
    --body-background-color: #FFF;
    --body-font-size: 14px;
    --body-text-color: #000000;

    /* Services */
    --hc-service-item-title-font-color: #000000;
    --hc-service-item-title-font-size: xx-large;

    --hc-service-item-body-font-color: #b13513;
    --hc-service-item-body-font-size: large;

    /* MISC */
    --btn-width: 120px;
    --btn-height: 40px;
    --block-padding: 60px;
}
