/* Modern form styles */
form {
  max-width: 500px;
  margin-top: 20rem;
  padding: 20rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}
h1 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 1rem;
  margin-top:0;
}

input[type="text"],
input[type="email"], 
input[type="password"],
input[type="number"],
input[type="tel"],
textarea,
select {
  width: 100%;
  padding: 12px;
  margin: 4px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  background-color: #ffffff; /* Solid white background */
  color: #333333; /* Darker text color for better contrast */
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.1); /* Subtle inner shadow */
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  border: 1px solid #b23212;
  box-shadow: 0 0 5px rgba(216, 102, 125, 0.3);
  background-color: #ffffff; /* Ensure background stays white when focused */
}

label {
  font-weight: 700;
  margin-bottom: 5px;
  display: block;
  color: #333;
}

.button_disabled {
  background-color: rgba(213, 96, 67, 0.963);
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  transition: background-color 0.3s ease;
}


.button_enabled {
  background-color: #b23212;
}

.form-group {
  margin-bottom: 20px;
}

/* The border is not visible because border-style was not specified */
.checkbox-group {
  border: 2px solid black; /* Fixed by specifying border style and color together */
  padding: 2px;
}

/* Error state */
input:invalid,
textarea:invalid {
  border: 1px solid #ff6b6b;
  background-color: #ffffff; /* Maintain white background in error state */
}

/* Success state */
input:valid:not(:placeholder-shown),
textarea:valid:not(:placeholder-shown) {
  border: 1px solid #b23212;
  background-color: #ffffff; /* Maintain white background in success state */
}

input[type="checkbox"] {
  appearance: auto; /* Resets any custom styling */
  width: 16px;
  height: 10px;
  margin-right: 2px;
}
.three-column-container {
  display: grid;
  grid-template-columns: auto auto auto; /* Columns expand based on content */
  gap: 1px; /* Spacing between columns */

  background-color: rgb(237, 233, 233); /* Background color for the container */
  border-radius: 10px;
}
.two-column-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Columns expand based on content */
  gap: 1px; /* Spacing between columns */
  padding: 1px; /* Optional padding around the grid */
  
}

.column {
  display: flex; /* Use Flexbox for alignment */
  align-items: center; /* Aligns content vertically in the center */
  justify-content: center; /* Optional: Centers content horizontally */
  text-align: center; /* Centers text inside each column */
  padding: 3px; /* Adds space inside each column */
}

.contact-form {
  padding-top: 10px;
}

/* For smaller screens */
@media (max-width: 768px) {
  .two-column-container {
      flex-direction: column; /* Stack columns vertically */
  }
  .column {
      margin-bottom: 20px; /* Add spacing between stacked columns */
  }
}


.botimage {
  width: 50px;
  height: 50px;
  max-width: 50px;
  margin-left: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  border-width: 3px;
  border-color: red;
}
.botStatus {
  color: red;
}

.success-message {
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  text-align: center;
  width: 100%;
  font-size: small;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

@media screen and (max-width: 768px) {
  .success-message {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    font-size: xx-large;  /* Adjust font size for better readability */
    z-index: 999;
  }
}
