
.footer {
    display: flex; /* Enable Flexbox */
    justify-content: space-between; /* Space between the columns */
    align-items: center; /* Align content vertically */
    flex-wrap: nowrap; /* Prevent wrapping */
    color: white; /* Text color */
    font-family: 'Arial', sans-serif; /* Font style */
    background-color: #B23212; /* Background color */
    font-size: 1rem; /* Default font size */
    flex-shrink: 0;
    bottom: 0;
    position: fixed;
    width: 100%;
  }
  
  .footer-left {
    text-align: left;
    align-items: center;
    display: flex;
  }
  
  .footer-right {
    text-align: right;
  }