/* Ensure the main content and footer coexist */
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the layout takes up the full viewport height */
}

main {
  flex: 1;
  margin-top: 40px;
  overflow-y: auto;
}

@media screen and (max-width: 768px) {
  .footer {
    font-size: 0.85rem; /* Reduce font size for mobile screens */
    padding: 8px 15px; 
  }
}